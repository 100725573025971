<template>
	<div class="px-4 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<v-col
				cols="12"
				align="center"
				class="pa-0 layout_align careflo"
			>
				<v-col
					align="center"
					class="pa-0"
				>
					<v-row class="ma-0 py-5 justify-center main_tit">
						<v-col
							class="pa-0"
							cols="auto"
							align-self="center"
						>
							<v-img
								width="24"
								eager
								:src="require('@/assets/img/careflo/icon_care_flo.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0 ml-1"
							align-self="center"
							cols="auto"
						>
							케어플로 서비스
						</v-col>
					</v-row>
					<p class="mb-5 sub_tit">
						사전관리 부터 사후관리 까지 <br />
						마루 전문 기업이 만든 확실한 케어서비스!
					</p>
				</v-col>

				<v-col
					class="pa-5 mb-5 section"
					cols="12"
				>
					<p class="mb-4 sub_tit">해당하는 유형을 선택해주세요.</p>
					<v-col
						class="pa-0 py-5 mb-4 btn_type"
						cols="12"
						@click="pushLink('customer')"
					>
						<v-row class="ma-0 justify-center">
							<v-col
								class="pa-0"
								align="end"
								align-self="center"
								cols="auto"
							>
								<v-icon
									width="20"
									color="#777"
									>mdi-account-circle-outline</v-icon
								>
							</v-col>
							<v-col
								class="pa-0 ml-1 tit"
								align="start"
								align-self="center"
								cols="auto"
							>
								구매자이신가요?
							</v-col>
						</v-row>
					</v-col>
					<v-col
						class="pa-0 py-5 btn_type"
						cols="12"
						@click="pushLink('store')"
					>
						<v-row class="ma-0 justify-center">
							<v-col
								class="pa-0"
								align="end"
								align-self="center"
								cols="auto"
							>
								<v-img
									width="20"
									eager
									:src="require('@/assets/img/careflo/icon_build_circle.svg')"
								/>
							</v-col>
							<v-col
								class="pa-0 ml-1 tit"
								align="start"
								align-self="center"
								cols="auto"
							>
								시공자이신가요?
							</v-col>
						</v-row>
					</v-col>
				</v-col>

				<v-col
					class="pa-0"
					align="center"
				>
					<v-btn
						class="btn_detail"
						text
						@click="openLink"
						>서비스 대상 및 혜택 자세히 보기</v-btn
					>
				</v-col>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import model from '@/mixins/model'
export default {
	name: 'CareFloHome',
	mixins: [model],
	data: () => ({
		nfc_code: null,
		operator: null,
		customer: null,
		event: 'N',
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.nfc_code = this.$route.params.param
		this.getNfcInfo()
	},
	methods: {
		...mapMutations(['CAREFLO_MU_AUTH_USER', 'APP_MU_DIALOG']),
		...mapActions(['CAREFLO_ACT_CAREFLO_NFC_CODE']),
		async getNfcInfo() {
			const items = { method: 'get', query: this.nfc_code }
			await this.CAREFLO_ACT_CAREFLO_NFC_CODE(items).then(res => {
				console.log('CAREFLO_ACT_CAREFLO_NFC_CODE', res)
				this.operator = res.operator_yn
				this.customer = res.cust_yn
				this.event = res.event_yn
			})
		},
		pushLink(item) {
			if (item === 'customer') {
				this.CAREFLO_MU_AUTH_USER({ customer: true, nfc_code: this.nfc_code })
				let param = this.customer === 'Y' ? 'login' : 'verification'
				this.$router.push(`/careflo/${item}/${param}`)
			} else {
				if (this.operator === 'N') {
					this.CAREFLO_MU_AUTH_USER({ store: true, nfc_code: this.nfc_code, event: this.event })
					this.$router.push(`/careflo/${item}/login`)
				} else {
					this.APP_MU_DIALOG({
						dialog: true,
						icon: 'careflo/icon_warning.svg',
						title: '접속 제한',
						text: '<br />시공 정보 입력이 완료되어 접속이 불가합니다.<br />수정 및 문의가 있으시면 고객센터로 연락주세요!<br /> <br />고객센터 : 1899-2837<br />(평일 09 ~ 17시)',
					})
				}
			}
		},
		openLink() {
			window.open('/custom/careFlo', '_blank')
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.careflo {
	max-width: 400px;
	.section {
		background-color: $color_white;
		border-radius: 16px;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

		.btn_type {
			cursor: pointer;
			border: 1px solid $color_gray_4;
			border-radius: 8px;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
			.tit {
				font-weight: $fw_regular;
				font-size: $font_xl;
				color: $color_font;
			}
		}
	}
}

.btn_detail {
	:deep(.v-btn__content) {
		font-size: $font_normal;
		color: $color_gray_6;
		border-bottom: 1px solid $color_gray_6;
	}
}
</style>
